<template>

  <div class="modal-overlay">
    <div class="modal-container">
      <div class="modal-header">
        <h4 class="header-text">Согласование закупки №{{ purchase.id }}</h4>
        <span style="margin-right: 10px; font-size: 17px ;"><b-icon  icon="x-lg" type="submit" @click="$emit('showModal')"></b-icon></span>
      </div>
      <div class="modal-body">
        <b-table
            v-if="purchase.histories"
            id="my-table"
            class="table__box"
            :borderless="true"
            :items="purchase.histories"
            :fields="fields"
            small
        >
          <template #cell(created_at)="data">
            <span>
              {{ new Date(data.item.created_at).toLocaleDateString() }}
            </span>
          </template>
          <template #cell(status)="data">
            <span>
              {{ status(data.item.status) }}
            </span>
          </template>
        </b-table>
        <button class="header__btn right-item default-btn modal__question-btn" v-if="purchase.status === 'questioned'" @click="sendAnswer()">
          Оставить ответ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    purchase: Object
  },
  data () {
    return {
      fields: [
        {
          key: "full_name",
          label: "ФИО",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "role",
          label: "Должность",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "created_at",
          label: "Дата согласования",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "comment",
          label: "Комментарий",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
      ],
    }
  },
  methods:{
    status(value) {
      if (value === "agreed") {
        return "Согласован";
      }
      else if (value === "consideration") {
        return "Рассмотрение";
      }
      else if (value === "questioned") {
        return "Запрошена доп. информация";
      }
      else if (value === "agreed_chief") {
        return "Согласовано старшим механиком";
      }
      else if (value === "agreed_analytic") {
        return "Согласовано аналитиком";
      }
      else if (value === "canceled") {
        return "Отменен";
      }
    },
    sendAnswer() {
      let comment = prompt('Оставьте комментарий', '')
      if (comment) {
        this.$api
            .post('/web/purchase/update/' + this.purchase.id, {
              comment: comment,
              user_id: this.$store.state.profileProducts.id,
              status: 'answered'
            })
            .then(() => {
              this.$emit('search');
              this.$emit('showModal');
              this.$toast.success("Успешно");
            })
            .catch(() => {
              this.$toast.error('Что-то пошло не так, свяжитесь с администратором');
            });
      }
    },

  }
}
</script>

<style scoped>
.modal-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
  transform: translateY(0%);
}
.modal-container {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  border-radius: 20px;
  max-width: 100%;
  min-width: 300px;
  z-index: 12;
}
.modal__question-btn {
  width: 100%;
  background: #E0E9FA;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0366D6;
  margin-right: 28px;
  margin-top: 20px;
}
</style>